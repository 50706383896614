<template>
  <div>
    <!-- <pre>
      dates: {{ dates }}
    </pre>
    sites: {{ sites }}<br />
    partnersConfigs: {{ partnersConfigs }} -->

    <v-container v-if="loading" class="text-center">
      <v-progress-linear color="primary" indeterminate></v-progress-linear>
    </v-container>

    <v-card
      v-else-if="
        sitesSeries.length === 0 ||
        ('data' in sitesSeries[0] && sitesSeries[0]['data'].length === 0)
      "
    >
      <v-card-text>{{ noDataText }}</v-card-text>
    </v-card>
    <div v-else>
      <div v-for="(siteSeries, index) in sitesSeries" :key="index">
        <div v-if="sitesSerieIsVisible[index]">
          <v-card class="mt-4">
            <v-card-title> {{ siteSeries.name }} </v-card-title>
            <v-card-subtitle>
              Total CA:
              <span class="text-ca">{{ siteSeries.total_ca }} €</span> /
              <span class="text-ca">{{ siteSeries.total_objective_ca }} €</span>
              &nbsp;◊◊◊&nbsp; Total sessions:
              <span class="text-sessions">{{ siteSeries.total_sessions }}</span>
              /
              <span class="text-sessions">{{
                siteSeries.total_objective_sessions
              }}</span>
            </v-card-subtitle>

            <vue-apex-charts
              type="line"
              :height="height"
              :options="chartOptions"
              :series="siteSeries.series"
            ></vue-apex-charts>
          </v-card>
          <v-row
            v-if="lastSitesSerieIsVisibleIndex < sitesSeries.length - 1"
            class="mt-5"
          >
            <v-col cols="3">
              Chargement du graph {{ lastSitesSerieIsVisibleIndex + 1 }} /
              {{ sitesSeries.length }}
            </v-col>
            <v-col cols="9">
              <v-progress-linear
                color="primary"
                :value="
                  100 *
                  ((lastSitesSerieIsVisibleIndex + 1) / sitesSeries.length)
                "
                class="mt-5"
              ></v-progress-linear>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { noDataText } from "@/utils/constants";
import axios, { cacheTime } from "@axios";
import debounce from "lodash/debounce";
import { colors, chartColors } from "@/utils/constants";
import { mdiAlert, mdiEye } from "@mdi/js";
import { formatNumber, prettyNbs } from "@/utils/formatting";
import { ONE_DAY_MICROTIME, getDaysArray, monthRange } from "@/utils/dates";

// const TODAY = new Date();
// const YESTERDAY = new Date(TODAY.getTime() - ONE_DAY_MICROTIME);

export default {
  name: "CaByPartnerViewSeparatedChart",
  components: {
    VueApexCharts,
  },
  props: {
    params: {
      type: Object,
      default: { partnersConfigs: [], sites: [], dates: [] },
    },
    /*
    partnersConfigs: {
      type: Array,
      default: () => [],
    },
    sites: {
      type: Array,
      default: () => [],
    },
    dates: {
      type: Array,
      default: () => [],
    },
    */
  },

  data() {
    return {
      icons: {
        mdiAlert,
        mdiEye,
      },
      sitesSeries: [],
      sitesSerieIsVisible: [],
      lastSitesSerieIsVisibleIndex: 0,
      noDataText: null,
      loading: false,
      title: "",
      legend: true,
      //series: [{ name: "", data: [] }],
      colorCA: colors.ca,
      colorSessions: colors.sessions,
      colors: [colors.ca, colors.ca, colors.sessions, colors.sessions],
      stroke: {
        show: true,
        curve: "smooth",
        width: 2,
        dashArray: [], // 0, 2
      },
      height: 300,
      labelsEnabled: false,
      /*
      yAxis: [
        {
          title: {
            text: "Revenue",
          },
          seriesName: "Revenue",
          labels: {
            formatter: (val) => formatNumber(val),
          },
          min: 0,
          forceNiceScale: true,
          labels: {
            style: {
              fontSize: "12px",
              fontWeight: 400,
            },
          },
        },
      ],
      */
      yAxis: [
        {
          title: {
            text: "Revenue",
          },
          seriesName: "Revenue",
          min: 0,
          forceNiceScale: true,
          labels: {
            formatter: (val) => formatNumber(val),
          },
        },
        {
          seriesName: "Revenue",
          min: 0,
          show: false,
          forceNiceScale: true,
          labels: {
            formatter: (val) => formatNumber(val),
          },
        },
        {
          title: {
            text: "Sessions",
          },
          seriesName: "Sessions",
          opposite: true,
          min: 0,
          forceNiceScale: true,
          labels: {
            formatter: (val) => formatNumber(val),
          },
        },
        {
          seriesName: "Sessions",
          show: false,
          min: 0,
          forceNiceScale: true,
          labels: {
            formatter: (val) => formatNumber(val),
          },
        },
      ],
      xAxis: {
        categories: [],
        labels: {
          rotate: -45,
        },
        style: {
          fontSize: "12px",
          fontWeight: 400,
        },
      },
      chartOptions: {
        chart: {
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6,
          },
        },
        grid: {
          borderColor: "#f1f1f1",
        },
      },
    };
  },
  created() {
    // console.log("created this.params", this.params);
    //this.debouncedGetData();
  },
  methods: {
    debouncedGetData: debounce(async function () {
      await this.getData();
    }, 500),
    async getData() {
      try {
        console.log("getData this.params.dates:");
        console.dir(this.params.dates);

        let queryParams = {
          group_ca: true,
          date_start: this.params.dates[0],
          date_end: this.params.dates[1],
          ...(this.params.sites && this.params.sites.length > 0
            ? { sites: this.params.sites.join(",") }
            : {}),
          ...(this.params.partnersConfigs &&
          this.params.partnersConfigs.length > 0
            ? { partnersconfigs: this.params.partnersConfigs.join(",") }
            : {}),
        };
        this.loading = true;
        const response = await axios.get(
          "/programmatic/ca-audience-objectives-by-site",
          {
            params: queryParams,
            cache: {
              maxAge: cacheTime,
            },
          }
        );
        this.loading = false;
        console.log("response");
        console.dir(response);

        if (!response.data || response.data.length === 0) {
          console.warn("No data available from the response");
          this.noDataText = "No data available";

          return;
        }
        let sitesData = response.data;
        const categories = getDaysArray(
          this.params.dates[0],
          this.params.dates[1],
          true
        );
        console.log("categories", categories);

        this.fillData(categories, sitesData);
        console.log("sitesData", sitesData);

        const annotations = {
          xaxis: [
            {
              x: "annotations xaxis.0 x",
              borderColor: "#FF0000",
              fillColor: "#FF0000",
              opacity: 0.6,
              label: {
                // borderColor: "#333",
                // style: {
                //   fontSize: "10px",
                //   color: "#333",
                //   background: "#FEB019",
                //   minWidth: "200px",
                //   minHeight: "80px",
                // },
                text: "xaxis.0 text",
              },
            },
          ],
        };

        this.chartOptions = {
          ...this.chartOptions,
          chart: {
            ...this.chartOptions.chart,
            id: this.title,
            height: this.height,
          },
          annotations: annotations,
          legend: {
            show: this.legend,
          },
          colors: this.colors,
          dataLabels: {
            enabled: this.labelsEnabled,
          },
          //stroke: this.stroke,
          stroke: {
            width: [2, 1, 2, 1],
            show: true,
            curve: "smooth", //"straight",
            dashArray: [0, 2, 0, 2],
          },
          xaxis: {
            categories: categories,
            labels: {
              rotate: -45,
            },
            style: {
              fontSize: "12px",
              fontWeight: 400,
            },
          },
          yaxis: this.yAxis,
        };
        /*
        sitesData = [
          {
            name: "mysite A"
            series: [
              { name: "CA", data: [5, 15, 18, ...] },
              { name: "CA Objectives", data: [21, 21, 21, ...] },
              { name: "Sessions", data: [300, 1100, 1005, ...] },
              { name: "Sessions Objectives", data: [553, 553, 553, ...] }
            ],
          },
          {
            name: "mysite B"
            series: [
              { name: "CA", data: [6, 9, 15, ...] },
              { name: "CA Objectives", data: [12, 12, 12, ...] },
              { name: "Sessions", data: [7865, 6358, 741, ...] },
              { name: "Sessions Objectives", data: [6874, 6874, 6874, ...] }
            ],
          },
          ...
        ];
        */

        // if (true) {
        //   // pendant les tests
        //   this.params.sitesSeries = this.params.sitesDataToSitesSeries(sitesData).slice(0, 1);
        //   return;
        // }
        this.sitesSeries = this.sitesDataToSitesSeries(sitesData);
        for (let item of this.sitesSeries) {
          console.log("siteSeries ---- ", item.name);
          console.dir(item);
        }
        this.displayGraphsOneByOne();
      } catch (error) {
        console.error("Error loading chart data:", error);
        this.noDataText = "Error loading data";
      }
    },
    async displayGraphsOneByOne() {
      for (let i = 0; i < this.sitesSeries.length; i++) {
        this.sitesSerieIsVisible.push(false); // Initialize all graphs as hidden
      }
      for (let i = 0; i < this.sitesSeries.length; i++) {
        await this.delayGraphDisplay(i); // Introduce a delay before showing each graph
        this.$set(this.sitesSerieIsVisible, i, true); // Display the graph one by one
        this.lastSitesSerieIsVisibleIndex = i;
      }
    },
    delayGraphDisplay(index) {
      return new Promise((resolve) => {
        setTimeout(resolve, 300 * index); // Adjust 300ms for desired delay
      });
    },
    sitesDataToSitesSeries(sitesData) {
      return sitesData.map((site) => {
        return {
          name: site.name,
          total_ca: prettyNbs(Math.round(site.total_ca)),
          total_sessions: prettyNbs(site.total_sessions),
          total_objective_ca: prettyNbs(
            Math.round(
              site.monthly_objective_ca_complete_array.reduce(
                (acc, current) => acc + current,
                0
              )
            )
          ),
          total_objective_sessions: prettyNbs(
            Math.round(
              site.monthly_objective_sessions_complete_array.reduce(
                (acc, current) => acc + current,
                0
              )
            )
          ),
          series: [
            { name: "CA", data: site.daily_ca_complete_array },
            {
              name: "CA Objectives",
              data: site.monthly_objective_ca_complete_array,
            },
            { name: "Sessions", data: site.daily_sessions_complete_array },
            {
              name: "Sessions Objectives",
              data: site.monthly_objective_sessions_complete_array,
            },
          ],
        };
      });
    },
    fillData(dateRange, sitesData) {
      // this function is meant to fill match a value for each day of the dateRange or null
      sitesData.forEach((site) => {
        Object.keys(site).forEach((prop) => {
          if (
            prop.includes("daily") &&
            typeof site[prop] === "object" &&
            site[prop] !== null &&
            !Array.isArray(site[prop])
          ) {
            const newPropName = `${prop}_complete_array`;
            site[newPropName] = dateRange.map((date) =>
              date in site[prop] ? site[prop][date] : null
            );
          } else if (
            prop.includes("monthly") &&
            typeof site[prop] === "object" &&
            site[prop] !== null &&
            !Array.isArray(site[prop])
          ) {
            const newPropName = `${prop}_complete_array`;
            const monthlyData = site[prop];

            // Create a map from 'YYYY-MM' to monthly values
            const monthValues = {};
            Object.keys(monthlyData).forEach((monthStartDate) => {
              const month = monthStartDate.slice(0, 7); // Extract 'YYYY-MM'
              monthValues[month] = monthlyData[monthStartDate];
            });

            site[newPropName] = dateRange.map((date) => {
              const month = date.slice(0, 7); // Extract 'YYYY-MM' from date
              if (month in monthValues) {
                // Calculate the number of days in the month
                const [year, monthNum] = month.split("-").map(Number);
                const daysInMonth = new Date(year, monthNum, 0).getDate();
                // Divide the monthly value by the number of days
                const dailyValue = monthValues[month] / daysInMonth;
                return Math.round(dailyValue);
              } else {
                return null;
              }
            });
          }
        });
      });
    },
  },

  watch: {
    params: function () {
      console.log("CaByPartnerViewSeparatedChart");
      console.log("-- watch params", this.params);
      if (!(this.params.dates && this.params.dates.length === 2)) {
        console.log("-- missing param dates");
        return;
      }
      if (
        !(this.params.partnersConfigs && this.params.partnersConfigs.length > 0)
      ) {
        console.log("-- missing param partnersConfigs");
        return;
      }
      if (!(this.params.sites && this.params.sites.length > 0)) {
        console.log("-- missing param sites");
        return;
      }

      console.log("-- get data");
      this.debouncedGetData();
    },
    // dates: function () {
    //   console.log("CaByPartnerViewSeparatedChart", this.name);
    //   console.log("watch dates", this.params.dates);
    //   if (this.params.dates) this.debouncedGetData();
    // },
    // partnersConfigs: function () {
    //   this.debouncedGetData();
    // },
    // sites: function () {
    //   this.debouncedGetData();
    // },
  },
  /**/
};
</script>
<style>
.text-ca {
  color: #feb019;
}
.text-sessions {
  color: #008ffb;
}
</style>
