<template>
  <div class="pa-6 ma-4">
    <p class="text-2xl mb-6">CA journalier par site</p>

    <v-expansion-panels class="mb-2">
      <v-expansion-panel>
        <v-expansion-panel-header> </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="ml-3">
            <v-col cols="6" sm="4" md="3">
              <partners-groups-filter store="premium"></partners-groups-filter>
            </v-col>
            <v-col cols="6" sm="4" md="6">
              <partners-views-filter
                store="premium"
                :showGroupCASwitch="false"
              ></partners-views-filter>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-card class="mb-0 pt-2">
      <v-row class="mt-3 mb-0 d-flex">
        <v-col cols="3" class="mb-0 pb-0">
          <!-- <date-filter
                  store="premium"
                  storeGetter="getMonthDate"
                  storeUpdater="updateMonthDate"
                  format="month"
                  :isRange="false"
                /> -->
          <date-range-filter
            @valueChanged="setDates"
            @frequencyChanged="setFrequency"
            periodType="past-and-today"
            format="date"
            :frequencies="['daily', 'weekly', 'monthly']"
            frequency="daily"
            range="PREVIOUS_AND_CURRENT_MONTH"
            :onCreatedEmitDates="true"
            :outlined="true"
            :allowNoLimit="false"
            wrapperClass="date-range-filter"
            :showOpenDialogBtn="false"
            class="mb-0 pb-0"
          ></date-range-filter
          ><!--:showBtnList="['CURRENT_MONTH']"-->
        </v-col>
        <v-col cols="3">
          <site-groups-filter store="premium" :dense="true" />
        </v-col>
        <v-col cols="4">
          <sites-filter store="premium" :dense="true" />
        </v-col>
        <v-col cols="1">
          <v-btn @click="applyFilters" outlined color="primary">
            APPLIQUER
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-row>
      <v-col cols="12">
        <ca-and-audience-by-site-separated-chart
          v-if="sites.length > 0 && dateSelected.length === 2"
          :params="applyedFilters"
        ></ca-and-audience-by-site-separated-chart>
        <!--:dates="dateSelected"
          :partners-configs="partnersConfigs"
          :sites="sites"-->
        <div v-else>Veuillez sélectionner un ou plusieurs sites</div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiCached } from "@mdi/js";
import SitesFilter from "@/components/common/filters/SitesFilter";
import SiteGroupsFilter from "@/components/common/filters/SiteGroupsFilter";
import ApplyFilters from "@/components/common/filters/ApplyFilters.vue";
import PartnersViewsFilter from "@/views/filters/premium/PartnersViewsFilter.vue";
import PartnersGroupsFilter from "@/views/filters/premium/PartnersGroupsFilter.vue";

import DateRangeFilter from "@/components/common/filters/DateRangeFilter.vue";
import CaAndAudienceBySiteSeparatedChart from "@/views/cards/premium/CaAndAudienceBySiteSeparatedChart";
import { saveRmraView } from "@/utils/rmr-analytics";
import {
  frequencyList,
  frequencyIdByName,
  getDateStr,
  ONE_DAY_MICROTIME,
} from "@/utils/dates";

export default {
  name: "CaAndAudienceBySite",
  components: {
    PartnersGroupsFilter,
    PartnersViewsFilter,
    ApplyFilters,
    SitesFilter,
    SiteGroupsFilter,
    mdiCached,
    DateRangeFilter,
    CaAndAudienceBySiteSeparatedChart,
  },
  mounted() {
    saveRmraView(this.$route);
  },
  async created() {
    // this.sites = [2, 126]; // uncomment for tests and sites in data and comment sites() in computed
    this.resetFilters();
    let siteGroups = this.$route.query.siteGroups;
    if (siteGroups) {
      siteGroups = siteGroups.split(",");
      await this.$store.dispatch("premium/updateSiteGroups", siteGroups);
    }
  },
  data() {
    return {
      // sites: [], // for tests
      applyedFilters: {},
      componentKey: 0,
      usePrettyNumbers: true,
      loading: false,
      frequencySelected: 2,
      dateList: [],
      dateSelected: [],
      icons: {
        mdiCached,
      },
    };
  },
  methods: {
    applyFilters() {
      console.log("applyFilters");
      this.applyedFilters = {
        sites: this.sites,
        dates: this.dateSelected,
        partnersConfigs: this.partnersConfigs,
      };
    },
    resetFilters() {
      // let monthDate = new Intl.DateTimeFormat("fr-FR")
      //   .format(new Date())
      //   .split("/");
      // this.$store.dispatch(
      //   "premium/updateMonthDate",
      //   `${monthDate[2]}-${monthDate[1]}`
      // );
      this.$store.dispatch("premium/updatePartnerViewsGroups", [9]);
    },
    getDateStr(date) {
      return getDateStr(date);
    },
    setDates(dates) {
      console.log("CaAndAudienceBySite DateRangeFilter $emit setDates", dates);
      this.dateSelected = dates;
    },
    prettyNbs(x, sep = " ") {
      if (typeof x == "undefined") return x;
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, sep);
    },
    setFrequency(frequencyObj) {
      console.log("setFrequency(frequencyObj)", frequencyObj);
      this.frequencySelected = frequencyObj.id;
    },
  },
  computed: {
    sites() {
      return this.$store.getters["premium/getSites"];
    },
    monthDate() {
      return this.$store.getters["premium/getMonthDate"];
    },
    partnersConfigs() {
      return this.$store.getters["premium/getPartnerViews"];
    },
  },
};
</script>
<style>
.v-input__control {
  padding-bottom: 0 !important;
}
</style>
